import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FixedImageQuery } from '../../utils/gatsby.types';
import Heading from '../heading/heading';
import * as styles from './download-button.module.scss';

interface Props {
  text: string;
  responsive?: boolean;
}

const DownloadButton = (props: Props): JSX.Element => {
  const { text, responsive } = props;
  const [modal, setModal] = useState(false);
  const toggle = (existing: boolean): void => setModal(!existing);

  const appleStoreLink =
    'https://apps.apple.com/app/hop-drive-on-demand/id1553986858';
  const googlePlayLink =
    'https://play.google.com/store/apps/details?id=com.hop.prod.android';

  const buttons = useStaticQuery<FixedImageQuery>(graphql`
    {
      appStore: file(relativePath: { eq: "app_store.png" }) {
        childImageSharp {
          fixed(height: 100, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      googlePlay: file(relativePath: { eq: "google_play.png" }) {
        childImageSharp {
          fixed(height: 100, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <div
        className={
          responsive
            ? styles['download-button-container-res']
            : styles['download-button-container']
        }
      >
        <button
          type="button"
          onClick={(): void => {
            toggle(modal);
          }}
          onKeyPress={(): void => {
            toggle(modal);
          }}
          className={styles['download-button']}
        >
          {text}
        </button>
      </div>
      <Modal
        isOpen={modal}
        toggle={(): void => {
          toggle(modal);
        }}
        centered
      >
        <ModalHeader
          toggle={(): void => {
            toggle(modal);
          }}
          className={styles['download-popup-title']}
        >
          <Heading size="main">Download From</Heading>
        </ModalHeader>
        <ModalBody>
          <div className={styles['download-popup-buttons-container']}>
            <a href={appleStoreLink}>
              <Img fixed={buttons.appStore.childImageSharp.fixed} />
            </a>
            <div className={styles.or}>or</div>
            <a href={googlePlayLink}>
              <Img fixed={buttons.googlePlay.childImageSharp.fixed} />
            </a>
          </div>
        </ModalBody>
        <ModalFooter />
      </Modal>
    </>
  );
};

export default DownloadButton;

import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { HelmetDataQuery } from '../../utils/gatsby.types';

const Head = (): JSX.Element => {
  const data: HelmetDataQuery = useStaticQuery<HelmetDataQuery>(graphql`
    {
      meta: site {
        siteMetadata {
          title
          siteUrl
          description
        }
      }
      images: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      favicon: file(relativePath: { eq: "HOPFav.png" }) {
        childImageSharp {
          fixed(height: 32) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const JsonLD = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: data.meta.siteMetadata.siteUrl,
    logo: `${data.meta.siteMetadata.siteUrl}${data.images.childImageSharp.fixed.src}`,
    name: `${data.meta.siteMetadata.title}`,
  };

  return (
    <Helmet>
      <title>{data.meta.siteMetadata.title}</title>
      <meta name="description" content={data.meta.siteMetadata.description} />
      <script type="application/ld+json">{`${JSON.stringify(JsonLD)}`}</script>
      <script
        src="https://kit.fontawesome.com/970ff357a9.js"
        crossOrigin="anonymous"
      />
      <link
        rel="icon"
        type="image/png"
        href={data.favicon.childImageSharp.fixed.src}
      />
      <html lang="en" />
    </Helmet>
  );
};

export default Head;

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FixedImageQuery, HelmetDataQuery } from '../../utils/gatsby.types';
import DevikaFooter from '../DevikaFooter/DevikaFooter';
import * as styles from './footer.module.scss';

const Footer = (): JSX.Element => {
  const data = useStaticQuery<FixedImageQuery & HelmetDataQuery>(graphql`
    {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 50, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      meta: site {
        siteMetadata {
          title
          facebook
          twitter
          instagram
        }
      }
    }
  `);

  const footerLinks = [
    {
      text: 'Terms and Conditions',
      link: '/terms-of-service',
    },
    {
      text: 'Privacy Policy',
      link: '/privacy',
    },
    {
      text: 'Cookie Notice',
      link: '/cookies-notice',
    },
  ];

  return (
    <>
      <div className={styles.footer}>
        <div className={styles['footer-logo']}>
          <Img fixed={data.logo.childImageSharp.fixed} />
        </div>
        <Container fluid>
          <Row>
            <Col lg={{ size: 6, offset: 3 }} className={styles['footer-legal']}>
              <span className={styles['footer-copy']}>
                &copy;
                {new Date().getFullYear()}
                &nbsp;HOP Technologies Pty Ltd &#46;
              </span>
              <ul>
                {footerLinks.map((link) => (
                  <li key={link.link}>
                    <a href={link.link}>{link.text}</a>
                  </li>
                ))}
              </ul>
            </Col>
            <Col md={12} lg={3}>
              <div className={styles['footer-brands']}>
                <a
                  className={styles.brand}
                  target="_blank"
                  rel="noreferrer"
                  href={data.meta.siteMetadata.facebook}
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  className={styles.brand}
                  target="_blank"
                  rel="noreferrer"
                  href={data.meta.siteMetadata.instagram}
                >
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <DevikaFooter />
    </>
  );
};

export default Footer;

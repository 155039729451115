import React from 'react';
import * as styles from './heading.module.scss';

interface Props {
  children: JSX.Element | string;
  size: 'main' | 'sub' | 'minor';
}

// This component exists for SEO purposes!
const Heading = (props: Props): JSX.Element => {
  const { children, size } = props;
  switch (size) {
    case 'main':
      return <h1 className={styles.main}>{children}</h1>;
    case 'sub':
      return <h2 className={styles.sub}>{children}</h2>;
    case 'minor':
      return <h2 className={styles.minor}>{children}</h2>;
    default:
      return <h1 className={styles.main}>{children}</h1>;
  }
};

export default Heading;

import { graphql, useStaticQuery } from 'gatsby';
import Img, { FixedObject } from 'gatsby-image';
import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FaBars } from 'react-icons/fa';
import { Container } from 'reactstrap';
import { FixedImageQuery } from '../../utils/gatsby.types';
import DownloadButton from '../download-button/download-button';
import ShopButton from '../shop-button/shop-button';
import * as styles from './navBar.module.scss';

const navBarHeight = '100';
const links = [
  {
    label: 'How it works',
    destination: '#howItWorks',
  },
  {
    label: 'FAQ',
    destination: '#faq',
  },
  {
    label: 'Contact',
    destination: '#contact',
  },
];

interface Props {
  home?: boolean;
}

const NavBar = (props: Props): JSX.Element => {
  const [mobileToggle, setMobileToggle] = useState<boolean>(false);
  const { home } = props;
  const logo: FixedObject = useStaticQuery<FixedImageQuery>(graphql`
    {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 100, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `).logo.childImageSharp.fixed;
  return (
    <>
      <div className={styles.announcement}>
        Due to COVID restrictions on supply, HOP is operating in limited release
        and will be increasing driver numbers in the coming weeks
      </div>
      <div className={styles['navbar-container']}>
        <Container>
          <div className={styles.navbar}>
            {home ? (
              <AnchorLink
                className={styles.logo}
                href="#heroImage"
                offset={100}
              >
                <Img fixed={logo} />
              </AnchorLink>
            ) : (
              <a className={styles.logo} href="/">
                <Img fixed={logo} />
              </a>
            )}
            <div className={styles['links-wrapper']}>
              {links.map((link) => {
                if (home) {
                  return (
                    <AnchorLink
                      key={`_${link.label}`}
                      href={link.destination}
                      className={styles.link}
                      offset={navBarHeight}
                    >
                      {link.label}
                    </AnchorLink>
                  );
                }
                return (
                  <a
                    key={`_${link.label}`}
                    href={`/${link.destination}`}
                    className={styles.link}
                  >
                    {link.label}
                  </a>
                );
              })}
              <ShopButton parentStyle={styles} />
              <DownloadButton text="DOWNLOAD" responsive />
              <div className={styles.hamburger}>
                <FaBars
                  onClick={(): void => {
                    setMobileToggle(!mobileToggle);
                  }}
                />
              </div>
            </div>
          </div>
        </Container>
        <div
          className={`${styles['links-wrapper-mobile']} ${
            styles[mobileToggle ? 'open' : 'closed']
          }`}
          onClick={(): void => {
            setMobileToggle(!mobileToggle);
          }}
        >
          {links.map((link) => {
            if (home) {
              return (
                <AnchorLink
                  key={`_${link.label}`}
                  href={link.destination}
                  className={styles.link}
                  offset={navBarHeight}
                >
                  {link.label}
                </AnchorLink>
              );
            }
            return (
              <a
                key={`_${link.label}`}
                href={`/${link.destination}`}
                className={styles.link}
              >
                {link.label}
              </a>
            );
          })}
          <DownloadButton text="DOWNLOAD" />
        </div>
      </div>
    </>
  );
};

export const NavBarSpacer = (): JSX.Element => {
  return <div style={{ marginTop: '100px' }} />;
};

export default NavBar;

import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { Button, Form, FormGroup, Input, Modal, ModalBody } from 'reactstrap';
import { HelmetDataQuery } from '../../utils/gatsby.types';
import * as styles from './shop-button.module.scss';

interface Props {
  parentStyle: any;
}

const ShopButton = (props: Props): JSX.Element => {
  const { parentStyle } = props;
  const [shopPopup, setShopPopup] = useState<boolean>(false);
  const [formStatus, setFormStatus] = useState('');

  const formURL = useStaticQuery<HelmetDataQuery>(graphql`
    {
      meta: site {
        siteMetadata {
          shopForm
        }
      }
    }
  `).meta.siteMetadata.shopForm;

  const submitForm = (e): void => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = (): void => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setFormStatus('SUCCESS');
      } else {
        setFormStatus('ERROR');
      }
    };
    xhr.send(data);
  };

  return (
    <>
      <button
        type="button"
        className={parentStyle.link}
        onClick={(): void => {
          setShopPopup(!shopPopup);
        }}
        onKeyPress={(): void => {
          setShopPopup(!shopPopup);
        }}
      >
        Shop
      </button>
      <Modal
        isOpen={shopPopup}
        toggle={() => {
          setShopPopup(!shopPopup);
        }}
        contentClassName={styles['modal-content']}
        centered
      >
        <ModalBody>
          <Form
            method="POST"
            action={formURL}
            onSubmit={(e): void => submitForm(e)}
            className={styles['shop-form']}
          >
            <div className={styles['shop-form-section-header']}>
              Your Details
            </div>
            <FormGroup>
              <Input
                type="text"
                id="contactGivenName"
                name="givenName"
                placeholder="First Name"
                aria-label="First Name"
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                id="contactGivenName"
                name="lastName"
                placeholder="Last Name"
                aria-label="Last Name"
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                id="contactPhoneNumber"
                name="phoneNumber"
                placeholder="Phone Number"
                aria-label="Phone Number"
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                id="contactEmail"
                name="email"
                placeholder="Email"
                aria-label="Email"
                required
              />
            </FormGroup>
            <div className={styles['shop-form-section-header']}>
              Billing Address
            </div>
            <FormGroup>
              <Input
                type="text"
                id="streetAddress"
                name="streetAddress"
                placeholder="Street Address"
                aria-label="Street Address"
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                id="streetAddress2"
                name="streetAddress2"
                placeholder="Street Address 2"
                aria-label="Street Address 2"
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                id="city"
                name="city"
                placeholder="City"
                aria-label="City"
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                id="state"
                name="state"
                placeholder="State"
                aria-label="State"
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                id="postcode"
                name="postcode"
                placeholder="Postcode"
                aria-label="Postcode"
                required
              />
            </FormGroup>
            <div className={styles['shop-form-section-header']}>
              What do you want to order?
            </div>
            <div className={styles['form-shop-products']}>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="hat"
                  name="hat"
                  placeholder="Hat"
                  aria-label="Hat"
                />
                <span className={styles['form-shop-product-label']}>Hat</span>
              </FormGroup>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="flag"
                  name="flag"
                  placeholder="Flag"
                  aria-label="Flag"
                />
                <span className={styles['form-shop-product-label']}>Flag</span>
              </FormGroup>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="jacket"
                  name="jacket"
                  placeholder="Jacket"
                  aria-label="Jacket"
                />
                <span className={styles['form-shop-product-label']}>
                  Jacket
                </span>
              </FormGroup>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="shirt"
                  name="shirt"
                  placeholder="Shirt"
                  aria-label="Shirt"
                />
                <span className={styles['form-shop-product-label']}>Shirt</span>
              </FormGroup>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  id="magnet"
                  name="magnet"
                  placeholder="Magnet"
                  aria-label="Magnet"
                />
                <span className={styles['form-shop-product-label']}>
                  Magnet
                </span>
              </FormGroup>
            </div>
            <FormGroup className={styles['shop-terms-and-service']} check>
              <Input
                type="checkbox"
                id="termsAndConditions"
                name="termsAndConditions"
                placeholder="Terms and Conditions"
                aria-label="Agree to Terms and Conditions"
                required
              />
              I agree to <a href="/terms-of-service">Hop’s terms of service</a>
            </FormGroup>
            {formStatus === 'SUCCESS' ? (
              <div>Form Submitted</div>
            ) : (
              <div className={styles['form-submit-wrapper']}>
                <Button className={styles['form-submit']}>Submit Order</Button>
                <button
                  type="button"
                  className={styles['cancel-button']}
                  onClick={(): void => {
                    setShopPopup(!shopPopup);
                  }}
                  onKeyPress={(): void => {
                    setShopPopup(!shopPopup);
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
            {formStatus === 'ERROR' ? (
              <div>There was an issue processing the form</div>
            ) : (
              <></>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ShopButton;

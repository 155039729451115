import React from 'react';

const DevikaFooter = (): JSX.Element => {
  return (
    <iframe
      src="https://storage.devika.com/iframefooter/"
      title="Branding"
      scrolling="no"
      style={{
        width: '100vw',
        height: '40px',
        overflow: 'hidden',
        margin: '-15px',
      }}
    />
  );
};

export default DevikaFooter;
